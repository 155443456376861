import {HttpClient, HttpParams} from "@angular/common/http"
import {Injectable} from "@angular/core"
import {HttpShowError, InvocationResult, QueryScope} from "hcl-lib"
import { DeviceConnectionStatus } from "../../../../../../hcl-lib/src/lib/interfaces/deviceConnectionStatus"
import {environment} from "projects/hcl-portal/src/environments/environment"
import {Observable} from "rxjs"
import {map} from "rxjs/operators"
import {Device} from "../../interfaces/device"
import {DeviceDto} from "../../interfaces/dto/device-dto"
import {KioskApplication} from "../../interfaces/kiosk-config"
import {DeviceStatus} from "../../interfaces/device-status"

@Injectable({
  providedIn: "root"
})
export class DeviceService {

  constructor(private httpClient: HttpClient) { }

  @HttpShowError()
  getDevicesWithPaging(
    page: number,
    perPage: number,
    tagIds?: string[],
    queryScope?: QueryScope,
    search?: string,
    statuses?: DeviceConnectionStatus[]
  ): Observable<InvocationResult> {
    const url = `${environment.deviceApiBaseUrl}/devices`
    let queryParams = new HttpParams()
      .set("perPage", perPage.toString())
      .set("page", page.toString())
    if (tagIds) {
      tagIds.forEach(id => {
        queryParams = queryParams.append("tagIds", id)
      })
    }
    if (queryScope) {
      queryParams = queryParams.append("queryScope", queryScope)
    }
    if (search) {
      queryParams = queryParams.append("search", search)
    }
    if (statuses) {
      statuses.forEach(status => {
        queryParams = queryParams.append("statuses", DeviceConnectionStatus[status])
      })
    }
    return this.httpClient.get(url, { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @HttpShowError()
  getDevice(id: string): Observable<Device> {
    const url = `${environment.deviceApiBaseUrl}/devices/${id}`
    return this.httpClient.get(url).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  createDevice(device: Device): Observable<Device> {
    const url = `${environment.deviceApiBaseUrl}/devices`
    const deviceDto: DeviceDto = {
      name: device.name,
      type: device.type,
      orientation: device.orientation,
      muted: device.muted,
      version: device.version,
      serialNumber: device.serialNumber,
      ipAddress: device.ipAddress,
      macAddresses: device.macAddresses,
      comment: device.comment,
      legacyScreenlabUser: device.legacyScreenlabUser,
      pairingCode: device.pairingCode,
      tagIds: device.tagIds,
      excludedTagIds: device.excludedTagIds,
      kioskConfig: device.kioskConfig,
      autoPowerOnOff: device.autoPowerOnOff,
      useRealTime: device.useRealTime,
      openingTimeSlots: device.openingTimeSlots,
      useUpdatingTimeSlots: device.useUpdatingTimeSlots,
      updatingTimeSlots: device.updatingTimeSlots,
      language: device.language,
      zoneId: device.zoneId
    }
    return this.httpClient.post(url, deviceDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  updateDevice(device: Device): Observable<Device> {
    const url = `${environment.deviceApiBaseUrl}/devices/${device.id}`
    const deviceDto: DeviceDto = {
      id: device.id,
      customerId: device.customerId,
      name: device.name,
      type: device.type,
      orientation: device.orientation,
      muted: device.muted,
      version: device.version,
      serialNumber: device.serialNumber,
      ipAddress: device.ipAddress,
      macAddresses: device.macAddresses,
      comment: device.comment,
      legacyScreenlabUser: device.legacyScreenlabUser,
      pairingCode: device.pairingCode,
      tagIds: device.tagIds,
      excludedTagIds: device.excludedTagIds,
      kioskConfig: device.kioskConfig,
      autoPowerOnOff: device.autoPowerOnOff,
      remoteControlConfigs: device.remoteControlConfigs,
      peripheralConfig: device.peripheralConfig,
      useRealTime: device.useRealTime,
      svdConfig: device.svdConfig,
      openingTimeSlots: device.openingTimeSlots,
      useUpdatingTimeSlots: device.useUpdatingTimeSlots,
      updatingTimeSlots: device.updatingTimeSlots,
      language: device.language,
      zoneId: device.zoneId
    }
    return this.httpClient.put(url, deviceDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  deleteDevice(id: string): Observable<any> {
    const url = `${environment.deviceApiBaseUrl}/devices/${id}`
    return this.httpClient.delete(url)
  }

  getDeviceStatus(id: string): Observable<DeviceStatus> {
    const url = `${environment.deviceApiBaseUrl}/devices/${id}/status`
    return this.httpClient.get(url).pipe(
      map((res: any) => {
        const deviceStatus = (res as InvocationResult).data
        deviceStatus.ts = new Date(deviceStatus.ts)
        return deviceStatus
      })
    )
  }

  migrateDevice(id: string, customerId: string): Observable<Device> {
    const url = `${environment.deviceApiBaseUrl}/devices/${id}/migrate`
    const migrateDeviceDto = {
      customerId
    }
    return this.httpClient.put(url, migrateDeviceDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  getDeviceKioskUrlByApp(id: string, app: KioskApplication): Observable<string> {
    const queryParams = new HttpParams()
      .set("app", app)

    const url = `${environment.deviceApiBaseUrl}/devices/${id}/kiosk-url`
    return this.httpClient.get(url, { params: queryParams, responseType: "text" }).pipe(
      map((url: string) => {
        return url
      })
    )
  }
}
